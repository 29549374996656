@import "mixing";

*{
  font-family:Helvetica Neue, Helvetica, Arial, sans-serif;
}

.main-bg{
background-color:#0D2F4A !important;
}

.sec-bg{
  background-color:#A2BD5C !important;
}

.third-bg{
  background-color:#BFB77E !important;
}

.grey-bg{
  background-color:#F0F0F0 !important;
}

.main-col{
  color:#0D2F4A !important;
}

.sec-col{
 color:#A2BD5C !important;
 }

.app-header{
  background-color:#0D2F4A;
  color:white;
  height:55px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.75);
}

.app-content{
  margin-top: 70px;

}

.no-wrap{
  white-space:nowrap;
}


#objTable_wrapper{
  position:relative;
 padding-top:70px;
  table td{
    font-size:90%;
    @media screen and (max-width: 800px) {
      font-size:70%;
    }
  }
}

.akttd{
  width:210px;
  .btn-wrap-tab{
    max-width:210px;
    width:210px;
  }

  @media screen and (max-width: 800px) {
    width:90px;
    .b1{
      margin-bottom:10px;
    }
    .b1,.b2{
       width:100%;
     }
  }

}


.bigLoader{

  position:fixed;
  left:0;
  top:0px;
  bottom:0;
  right:0;
  z-index:8888;
  opacity:0.8;
  background-color:white;
  text-align:center;


}


.overflower{
  @media screen and (max-width: 800px) {
    overflow:auto;

  }

}
#objTable_paginate,#objTable_info{
  font-size:80%;
}
@media screen and (max-width: 800px) {
  #objTable_paginate,#objTable_info{
    font-size:70%;
  }
}

.adressetd{
  min-width:140px;
}
.wnr{
  @media screen and (max-width: 800px) {
    min-width:100px;
  }
  min-width:160px;
}



.infotabletd{
  min-width:180px;
  @media screen and (max-width: 800px) {
    min-width:240px;
  }


}

#objTable_filter{
  position:absolute;
  left:0;
  top:0;
  width:60%;
  @media screen and (min-width: 800px) {
    width:80%
  }
  input,label{
    width:100% !important;
    max-width:unset;

    padding:5px;
    font-size:120%;
  }

}

#objTable_length{
text-align:center;
  position:absolute;
  right:0;
  top:0;
  width:40%;
  @media screen and (min-width: 800px) {
   width:20%
  }


   label{
     text-align:center;
    width:150px !important;
    max-width:unset;

    padding:5px;
    font-size:90%;
  }
  select{
    padding:5px;
    font-size:120%;
    margin-top:5px;
    display:inline-block;
    width:70% !important;
  }

}


.topBar{


}
.topBar{
  justify-content:between !important;





  display:flex ;
  @media screen and (max-width: 800px) {

    display:block;

    .flLine{
      padding-top:5px;
      display:block !important;
      width:100% !important;

    }
    .sl{
      display:block !important;
      text-align:right;
      padding-top:10px;
    }
  }
}

.wrap-formline{
  display:flex;
  justify-content:space-between ;
  .formfield{
    width:45%;
  }
  margin-bottom:10px;
}




/*
.wrap-formline4{
  background-color:green;
  @media screen and (min-width: 800px) {
    display: flex;
    justify-content: space-between;
    .formfield {
      width: 45%;
    }
  }

  @media screen and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
    .formfield {
      width: 25%;
    }
  }




}
.objcont{
  background-color:green;
}
*/
.isError{
  color:darkred !important;
.lab,ion-label{
  color:darkred !important;
}
}

.s-between{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  justify-content:space-between ;
}

.wrap-formline4{
  width:100%;
  margin-bottom:10px;
  @media screen and (min-width: 800px) {
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content:space-between ;

    .formfield{
      width:48%  ;

      &.f00{
      width:25%
    }
      &.f0 {
        width:75%
      }

    }
  }
  @media screen and (min-width: 1000px) {

    .formfield{
      width:24%;
      &.f1{
        width:14% !important;
      }
      &.f2{
        width:34% !important;
      }


    }
  }

  .formfield{
    background-color:orange;
  }

}
.mw-4400{
  max-width:315px;

  @media screen and (min-width: 1000px) {
    max-width:465px;
  }

}
.nb-card{
  border-radius:5px;

  padding-bottom:25px;

}

.infoboard{
  span{
    display:inline-block;
    padding:5px 30px;
    @media screen and (max-width: 1100px) {
      padding:5px 5px;
    }
    border:1px solid #efefef;
    border-radius:5px;
  }

  label{
    color:#0D2F4A;
    display:block;
    border-bottom:1px solid #ddd;
    font-size:80%;
  }
}

.flying-menu{
  position:absolute;
  top:70px;
  right:18px;
  z-index:999;
  border-radius:3px;
  background-color:rgb(248,248,248);
 .del,.dlin{
   cursor:pointer;
 }
}

.anwesenheitscheck{

  @include transition_all(0,5);
  position: absolute;
  left:0;
  top:56px;
  bottom:0;
  right:0;
  overflow:auto;
  z-index:99;

  &.is_checked{
    z-index:-1;
    display:none;

  }

  .awbox{
    width:100%;
    min-height:320px;
    max-width:360px;
    border-radius:5px;
  }

}

.prozent-wrap{
  width:100%;
  border:1px solid #ddd;
  border-radius:2px;
  height:16px;
  min-height:16px;
  margin-top:8px;
  border-bottom:1px solid #ccc;
  background-color:rgba(darkred,0.5);
  position:relative;

  .prozenter{
    @include transition_all(0.8s);
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    width:0;
    background-color:green;
  }
}

.headerProtoCard{
  font-size:80%;
  strong{
    font-size:105%;
  }

}

.item-light{
  --ion-item-background:#efefef;
  --border-radius:2 !important;
  margin-bottom:6px !important;
  font-size:90%;

}

pre{
  display:block;
  max-height:100vh;
  overflow:auto;
  background-color:#efefef;
  color:darkred;
  font-size:80%;
  padding:10px;
}

.sff{
  background-color:rgb(246,246,246);
}

.feldart-6,.feldart-5{

  width:100%;
  margin-bottom:2px;

  @media screen and (min-width: 768px) {
      width:31%;
      margin:1%;
  }


  @media screen and (min-width: 992px) {
    width:23%;
  }

margin-left:0 !important;

}

.feldart-1,.feldart-10,.feldart-4,.feldart-2{
  width:100%;
  margin-bottom:2px;
}

.numberput{
  --background:#efefef;
  --padding-end:2px;
  --padding-start:2px;

  &.notchang{
    --placeholder-color:white;
    --background:darkred;
    --color:white;
  }
}
.nb-countput{
  .up{

  }
}
.triggergroup{



  .isf1{
    margin-left:10px;
    background-color:orange;
    @media screen and (min-width: 992px) {
      margin-bottom:-18px !important;
    }

  }

}

.icHoldo{

  position:relative;
  padding-bottom:8px;


}

.noMob{
  @media screen and (max-width: 991px) {
    display:none;
  }
}

.topIcs {

  @media screen and (max-width: 991px) {
    width:24px;
    height: 24px;

  }


}


.taborder{
  border:1px solid #ddd !important;
}

.sektop {
  font-size:120%;
  font-weight:400;


}
.topFlyOn{
  z-index:999;
  color:white;
  background-color:#ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: sticky;
  top:  0px;
  .sektop {
    font-weight:900;

  }
}

.maintb{
  --background:#8db624; //#A2BD5C !important;
  --color:white;
}

.secentb{
  --background:#11314E; //#A2BD5C !important;
  --color:white;
}


.ion-tab-bar{
  z-index:-1;
}

.StaticButton{
  position:fixed;
  right:0;
  bottom:10px;
  z-index:9999;
}

.toToppy{
  z-index:9999;
  color:white;

  opacity:0.94;
  position: sticky;
  top:  0px;
  right:0;


  .sektop {
    font-weight:900;
    color:#A2BD5C;
  }

}






.nb-btn{
  border-radius:5px;
  padding:5px 10px;
  background-color:#efefef;
  border:1px solid #ddd;
  color:#0D2F4A;
  cursor:pointer;
  margin-bottom:5px;
  margin-top:5px;

}

.hideCont{
  --background:transparent;
}






.IMAGELISTE{
  .img-wrapp{
    position:relative;
    width:100%;
    max-width:450px;
    margin:5px auto;

    @media screen and (min-width: 768px) {
      margin:unset;
      max-width:unset;
      width:32%;
      margin-right:1.3%;
      margin-bottom:1%;
    }

    .deleteer{
      position:absolute;
      right: 0px;
      top: 0px;
      opacity:0.8;
      color:darkred;
      background-color:white;

       font-size:120%;
      line-height:120%;
      font-weight:200;
      padding:0;
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
       align-items:center;
      justify-content:center;
    }

  }


}

.listFlyer{
  z-index:9999;
  color:white;
  background-color:#0D2F4A;
  opacity:0.94;
  position: sticky;
  top:  0px;
  right:0;



}


.gruppenwrap{
  background-color:rgb(253,253,253);
  margin-bottom:8px;
  padding:10px;
  border-radius:5px;
}

.big {
  font-size: 115%;
  font-weight: 600;
}

.gr-name {


}



.listtops{
  h2{
    font-size:145%;
  }
  h3{
    font-size:135%;
  }
  h4{
    font-size:120%;
  }
  h5{
    font-size:110%;
  }
  h6{
    font-size:105%;
  }
}

.loaderanimater{
  position:absolute;
  top:0;
  opacity:0.92;

  left: 0px;
  right: 0px;
  background-color:darkred;
border-bottom:1px solid #ddd;

  .loadstat{
    border-top:1px solid #333;
    @include transition_all(0.4s);
    height:6px;
    background-color:red;
  }
}

.staticzent{
  z-index:99999;
  color:#A2BD5C;
background-color:white;
  opacity:0.94;
  position: sticky;
  bottom:  0px;


}

.br20{
  border-radius:5px;

}

.fna{
  position:absolute;
  left:0;
  right:0;
  font-size:80%;

  bottom:4px;
  opacity:0.7;
}

.ls-min{
  min-width:300px;
  font-weight:600;

}

.signature-wrap{
  width:100%;
  margin:0 auto;
  max-width:600px;
  background-color:rgba(250,250,250,0.6);
  border-bottom:2px dotted #999;
  min-height:115px;
}

.sigblock{
  .buttons{
    margin:0 auto;
    max-width:600px !important;

  }
}

.abschluss-content{

  @media screen and (max-width: 480px) {
    font-size:80%;

    .ls-min{
      min-width:150px;
      max-width:150px;

    }

  }

  @media screen and (max-width: 767px) and (min-width: 480px)  {
     font-size:90%;

    .ls-min{
      min-width:180px;
      max-width:180px;

    }

  }
  @media screen and   (min-width: 991px)  {
    font-size:115%;

    .ls-min{
      min-width:300px;
      max-width:300px;

    }

  }

}

.loader{
  background-color:#ededed;
  border-bottom:1px solid #ddd;

}


.flyerBtn {
  position: absolute;
  top: 10px;
  right: 80px;

}

.FELDER {

  .flyerBtn {
    position: absolute;
    top: 0;

    right: 80px;

  }
}

.dslist{
  strong.label{
    display:inline-block;
    min-height:35px;
  }
}

.mengenanzeiger{
  color:green;
}

.hasError{

  h5.sektop{
    color:darkred !important;
  }
  .mengenanzeiger{
    color:darkred;
  }
}


.dashbtn{
  min-width:210px;

}


@import "reparatur";
